import {
  Box,
  Button,
  Flex, Image,
  ListItem,
  Stack, Text,
  UnorderedList
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

export const WeightLossDetails = ({name, symptoms, whatYouNeed, commonMedications, removeMargin=false}) => {

  // Mapping of short names to longer descriptions
  const whatYouNeedDescriptions = {
    'Meds': 'Current medication list (Example: Lipitor, 10mg, daily at night)',
    'Allergies': 'Medication and food allergies (Example: penicillin, peanuts)',
    'Conditions': 'Medical conditions (Example: High blood pressure, diabetic)',
    'Picture Condition': 'Picture of your condition',
    'BP Reading': 'Blood pressure reading',
    'PhotoOfTest': 'Picture of at home test results',
    'Lab Result': 'Any recent lab results',
    'Rx Picture': 'Picture of your prescription',
    'Thermometer': 'Thermometer to take temperature',
    'PCP': 'Primary Care Physician Information - your doctor’s name and telephone number',
    'Vitals': 'Recent blood pressure, pulse rate, and temperature readings',
  };

  // Function to get the long description
  const getLongDescription = (shortName) => {
    return whatYouNeedDescriptions[shortName] || shortName; // Fallback to shortName if no mapping is found
  }

  console.log("symptoms",symptoms,"name",name)
  const { pathname } = useLocation();
  console.log(pathname)

  return (
    <>
      <Flex
        as="section"
        background="white"
        pt={{ base: 6, md: 8 }}
        pb={removeMargin ? 0 : { base: 2, md: 8 }}
        mx={removeMargin ? 0 : { base: 4, md: 8 }}
        justifyContent={'start'}
      >
        <Flex>
          <Stack spacing="3" w={'100%'}>

            <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight={'bold'} color={'secondaryBlue.default'}>
              Get Professionally Compounded GLP-1 Medication - Semaglutide
            </Text>
            <Text fontSize={{ base: 'lg', md: 'xl' }} pt={2} fontWeight={'bold'}>
              IN STOCK AND READY TO SHIP!
            </Text>
            <Text fontSize={{ base: '2xl', md: '3xl' }} py={1} color={'primaryOrange.default'} fontWeight={'1000'}>
              $299/month Including Doctor Visits
            </Text>

            <UnorderedList fontSize={{ base: 'md', md: 'lg' }} pl={5}>
              <ListItem>Same active ingredient as <Box as="span" fontWeight='bold' color={'primaryBlue.default'}>Ozempic® and Wegovy®</Box></ListItem>
              <ListItem><Box as="span" fontWeight='bold' color={'primaryBlue.default'}>SAVE up to 70%</Box> over branded medication</ListItem> 
              <ListItem><Box as="span" fontWeight='bold' color={'primaryBlue.default'}>NO HIDDEN FEES</Box> or Long-Term Commitments</ListItem>
              <ListItem><Box as="span" fontWeight='bold' color={'primaryBlue.default'}>Vitamin B-12</Box> added to increase energy and reduce common side effects</ListItem>
              <ListItem><Box as="span" fontWeight='bold' color={'primaryBlue.default'}>Lab Tests</Box> provided by <Box as="span" fontWeight='bold' color={'primaryBlue.default'}>Quest Diagnostics</Box></ListItem>
            </UnorderedList>
            <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight={'bold'} color={'primaryBlue.default'} pt={5}>
              Weight Loss Program Journey
            </Text>
            <Text fontSize={{ base: 'md', md: 'lg' }} py={0}>
              Whether you’re a new or existing patient, your Symliphy weight loss journey will consist of 3 simple steps:
            </Text>
            <Image src="../images/WeightLossHowItWorks.png" w={'600px'} maxW={'100%'} pr={5} py={5}></Image>
            
            <Flex w={'100%'} flexDirection={'column'} mt={4}>
              <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight={'bold'} color={'primaryBlue.default'} pb={3}>
                Risk Free To Get Started
              </Text>
              <Text fontSize={{ base: 'md', md: 'lg' }} pt={1}>
                No prescription. No charge. The process is fast and easy. Click on the How It Works button below to review a detailed breakdown of the process.
              </Text>
              <Button variant={'primaryBlueOutline'} size="lg" my={5} maxW={'350px'}
                onClick={() => window.open('/how-it-works?tab=2', '_blank')}
              >
                How It Works
              </Button>
            </Flex>  

            {/* What You Need Data */}
            {whatYouNeed && whatYouNeed.length > 0 &&
              <Flex w={'100%'} flexDirection={'column'}>
                <Text fontSize={{ base: 'xl', md: '2xl' }} fontWeight={'bold'} color={'primaryBlue.default'} pb={3}>
                  What You Need
                </Text>
                <Box fontSize={{ base: 'md', md: 'lg' }}>
                  <UnorderedList pl={5}>
                    {whatYouNeed.map((item,index) => (
                      <ListItem key={index}>
                        <Text as="span">{getLongDescription(item)}</Text>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </Box>
                <Text fontSize={{ base: 'md', md: 'md' }} fontWeight={'bold'} color={'primaryOrange.default'} pt={3}>
                  Missing any of these items? Don't worry! Just bring what you have and your provider can help you with the rest.
                </Text>
                <Button variant={'primaryBlueOutline'} size="lg" my={5} maxW={'350px'}
                onClick={() => window.open('/faqs?section=weight-loss', '_blank')}
                >
                  Frequently Asked Questions
                </Button>
              </Flex>
            }
            {/* END What You Need Data */}

            {/* Disclaimer Box */}
            <Box border={'solid 1px #888'} w={'100%'} mt={5} mb={3} p={4}>
              <Text fontSize={'sm'}>
                Wegovy® and Ozempic° are registered trademarks of Novo Nordisk A/S. Novo Nordisk is a registered trademark of Novo Nordisk A/S.
              </Text>
              <Text fontSize={'sm'} pt={1}>
                Compounded semaglutide is not approved or evaluated for safety, efficacy, or quality by the FDA.
              </Text>
            </Box>
            {/* END Disclaimer Box */}
          </Stack>
        </Flex>
      </Flex>
    </>
  )
}